import styled from 'styled-components/macro';
import { lighten } from 'polished';
import tw from 'tailwind.macro';

export const Timeline = styled.div`
  ${tw`flex flex-col sm:flex-row w-full p-4 relative border-l`};
  border-color: ${(p) => p.theme.colors.accentColor};

  &:last-child {
    ${tw`pb-0`};
  }
`;

export const Details = styled.div`
  ${tw`w-full sm:w-1/3`};
`;

export const Content = styled.div`
  ${tw`w-full sm:w-2/3 mt-4 ml-0 sm:ml-6 sm:mt-0 `};

  ul {
    margin-left: 1rem;
    ${tw`list-disc list-outside`};
    li {
      ${tw`mb-2`}
    }

    li:last-child {
      ${tw`mb-0`}
    }
  }
`;

export const Title = styled.div`
  ${tw`font-semibold mt-3`};
`;

export const Subtitle = styled.div`
  ${tw`text-xs`};
`;

export const Date = styled.div`
  border-color: ${(p) => p.theme.colors.primaryColor};
  ${tw`text-xs border rounded-full px-2`};
  width: fit-content;
`;

export const Point = styled.span`
  border-color: ${(p) => p.theme.colors.accentColor};
  background-color: ${(p) => lighten(0.1, p.theme.colors.accentColor)};
  ${tw`w-3 h-3 border rounded-full absolute`};
  left: -6px;
  top: 20px;
`;
