import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Education from 'components/Education';
import Skills from 'components/Skills';
import * as SocialStyles from 'components/HeroBanner/styles';
import Socials from 'components/ui/Socials';

const ResumePage: React.FC = () => (
  <Layout>
    <SEO
      title="Resume"
      description="My resume including work experience, education, and skills I am most confident in."
    />
    <SocialStyles.FixedSocials>
      <Socials />
    </SocialStyles.FixedSocials>
    <Experience />
    <hr />
    <Education />
    <hr />
    <Skills />
  </Layout>
);

export default ResumePage;
