import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Timeline from 'components/ui/Timeline';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

import { SectionTitle } from 'helpers/definitions';

const INDUSTRY_TO_COUNT = 'software';
const PRESENT_STRING = 'Present';
const MILLIS_IN_YEAR = 31536000000;

interface Experience {
  node: {
    id: string;
    html: React.ReactNode;
    frontmatter: {
      company: string;
      position: string;
      startDate: string;
      endDate: string;
      industry: string;
    };
  };
}

const Experience: React.FC = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "experiences section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "experiences" } }, published: { eq: true } }
        sort: { order: DESC, fields: [frontmatter___startDate, frontmatter___endDate] }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              company
              position
              startDate
              endDate
              industry
            }
          }
        }
      }
    }
  `);

  const sectionTitle: SectionTitle = markdownRemark.frontmatter;
  const experiences: Experience[] = allMarkdownRemark.edges;

  const calculateIndustryExperience = () => {
    let yearsOfExperience = 0;
    experiences.forEach((experience: Experience) => {
      const { industry, endDate, startDate } = experience.node.frontmatter;
      if (industry === INDUSTRY_TO_COUNT) {
        const start = new Date(startDate);
        let end = new Date();
        if (endDate !== PRESENT_STRING) {
          end = new Date(endDate);
        }
        yearsOfExperience += (end.getTime() - start.getTime()) / MILLIS_IN_YEAR;
      }
    });
    return yearsOfExperience.toFixed(1);
  };

  return (
    <Container section>
      <TitleSection
        title={`${sectionTitle.title}: ${calculateIndustryExperience()} years`}
        subtitle={sectionTitle.subtitle}
      />

      {experiences.map((item) => {
        const {
          id,
          html,
          frontmatter: { company, position, startDate, endDate },
        } = item.node;

        return (
          <Timeline
            key={id}
            title={company}
            subtitle={position}
            content={<FormatHtml content={html} />}
            startDate={startDate}
            endDate={endDate}
          />
        );
      })}
    </Container>
  );
};

export default Experience;
