import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

export const Socials = styled.div`
  ${tw`invisible lg:visible absolute top-0 bottom-0 left-0 flex flex-col items-center justify-center`};

  a {
    ${tw`m-0 ml-3 my-2`}
  }
`;

export const FixedSocials = styled.div`
  ${tw`invisible lg:visible fixed top-0 bottom-0 left-0 flex flex-col items-center justify-center`};

  a {
    ${tw`m-0 ml-3 my-2`}
  }
`;
